import React, { Fragment, useState } from 'react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import Link from 'src/components/Link'
import AnimatedLogo from 'src/components/Logo'
import Grid from 'src/components/Grid'
import ScrollListener from 'src/components/ScrollListener'
import NotificationBanner from 'src/components/NotificationBanner'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import AnimatedIcon from 'src/components/AnimatedIcon'
import MobileMenu from 'src/components/MobileMenu'
import { colors, typography, animations, mq, util } from 'src/styles'

const showHide = false // show and hide header on scroll
const headerHeight = (additionalHeight = 0) => util.responsiveStyles('height', (140 + additionalHeight), (130 + additionalHeight), (90 + additionalHeight), (90 + additionalHeight))
const headerHeightCollapsed = util.responsiveStyles('height', 0, 0, 0, 0)

const Dropdown = styled.ul`
  list-style: none;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 200px;
  background: ${ colors.mainColor };
  color: ${ colors.bgColor };
  ${ typography.bodySmall };
  font-weight: 600;
  letter-spacing: 0;
  padding: 10px 16px;
  visibility: hidden;
  opacity: 0;
  transition: visibility ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
  a {
    display: inline-block;
    padding: 3px 0;
    opacity: .6;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 100%;
      width: 5px;
      height: 5px;
      transform: rotate(-45deg);
      border-bottom: 2px solid;
      border-right: 2px solid;
      border-color: ${ colors.mainColor };
      top: 50%;
      margin-top: -3px;
      opacity: 0;
      transition: transform ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
    }
    &:hover {
      opacity: 1;
      &:after {
        opacity: 1;
        transform: rotate(-45deg) translate3d(5px, 5px, 0);
      }
    }
  }
`

const NavLinkStyle = (scrolled, active, hasAtf, dropdown) => `
	display: block;
	font-family: ${ typography.secondaryFont };
	line-height: 1.25em;
	letter-spacing: .1em;
	text-transform: uppercase;
  font-size: 12px;
  font-weight: normal;
	line-height: 1em;
	transition: 	padding ${ animations.mediumSpeed } ease-in-out,
								margin ${ animations.mediumSpeed } ease-in-out,
                background ${ animations.mediumSpeed } ease-in-out,
								color ${ animations.mediumSpeed } ease-in-out;
  ${ !active ? `
    &:hover {
      ${ hasAtf ? `
        opacity: .6;
      ` : `
        color: ${ colors.mainColor };
      ` }
    }
  ` : `
    ${ hasAtf ? `
      color: inherit;
    ` : `
      color: ${ colors.mainColor };
    ` }
  ` }
  color: inherit;
`

const NavLink = styled(Link)`
  ${ props => NavLinkStyle(props.scrolled, props.active, props.hasAtf, props.hasDropdown) }
`

const Wrapper = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 4;
  // background: chartreuse;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  transition: height ${ animations.mediumSpeed } ease-in-out,
              background ${ animations.mediumSpeed } ease-in-out,
              transform ${ animations.mediumSpeed } ease-in-out,
              box-shadow ${ animations.mediumSpeed } ease-in-out;
  ${ ({ scrolled, hasAtf, mobileMenuOpen }) => scrolled ? `
		${ headerHeightCollapsed }
		background: ${ colors.transparent };
		color: ${ colors.textColor };
		// box-shadow: 0 1px 0 ${ rgba(colors.textColor, 0.1) };
    ${ mq.mediumAndBelow } {
      ${ mobileMenuOpen ? `
        background: transparent;
        box-shadow: none;
        height: 0;
      ` : `` }
    }
	` : `
		${ headerHeight() }
    ${ mq.mediumAndBelow } {
      ${ mobileMenuOpen ? `
        ${ headerHeightCollapsed }
      ` : `` }
    }
		background: transparent;
		${ hasAtf ? `
			color: ${ colors.bgColor };
		` : `
			color: ${ colors.textColor };
		` }
	` }
  ${ ({ navVisible }) => navVisible && `
		transform: translate3d(0, -101%, 0);
	` }
`

const HeaderContent = styled(Grid)``

const HeaderLogo = styled(AnimatedLogo)`
	${ util.responsiveStyles('width', 80, 80, 80, 65) }
	height: auto;
	transition: color ${ animations.mediumSpeed } ease-in-out, width ${ animations.mediumSpeed } ease-in-out, visibility ${ animations.mediumSpeed } ease-in-out;
	${ ({ scrolled, mobileMenuOpen }) => mobileMenuOpen ? `
		color: ${ colors.mainColor };
    ${ mq.mediumAndBelow } {
        ${ util.responsiveStyles('width', 56, 56, 56, 56) }
      ${ scrolled ? `
      
      ` : `` }
    }
	` : `
    ${ mq.mediumAndBelow } {
      ${ scrolled ? `
      ` : `` }
    }
  ` }
`

const LogoCol = styled.div`
    position: fixed;
    top: 20px;
    right: 0;
    z-index: 5;
    padding-right: 3.57vw;
	// text-align: right;
	// a {
	// 	display: inline-block;
	// 	vertical-align: top;
  //   transition: none;
  //   max-width: 100%;
	// }
  ${ mq.mediumAndBelow } {
    top: 10px;
  }
`
const LogoWrapper = styled.div`
  
`

const NavLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
  transition: visibility ${ animations.mediumSpeed } ease-in-out;
  li {
    position: relative;
    flex-shrink: 0; 
    &:hover {
      ${ Dropdown } {
        visibility: visible;
        opacity: 1;
      }
    }
    &:not(:last-of-type) {
      ${ util.responsiveStyles('margin-right', 20, 20, 10, 10) }
    }
  }
  ${ ({ scrolled }) => scrolled ? `
      visibility: hidden;
	` : `	` }
`

const MenuIcon = styled.li`
	display: none;
  margin-left: -10px;
  margin-right: -10px;
  cursor: pointer;
  display: block;
  vertical-align: top;
  transition: color ${ animations.mediumSpeed } ease-in-out;
  button {
    padding: 5px 10px;
    display: block;
    outline: none;
    border: none;
    background: transparent;
    appearance: none;
    color: inherit;
  }
  span {
    display: block;
  }
`

const DesktopNav = styled.div``

const HeaderPlaceholder = styled.div`
	background: transparent;
	width: 100%;
	transition: height ${ animations.mediumSpeed } ease-in-out;
	${ ({ hasBanner }) => hasBanner ? `
		${ headerHeight(40) }
	` : `
		${ headerHeight() }
	` }
`

const HeaderNotificationBanner = styled(NotificationBanner)`
	position: relative;
	z-index: 5;
`
const HeaderCenter = styled.div`

`


const PageTitle = styled.div`
  h1 {
    transition: visibility ${ animations.fastSpeed } ease-in-out, color ${ animations.mediumSpeed } ease-in-out;
    ${ typography.pageTitle }
    font-weight: 300;
    visibility: visible;
    ${ ({ scrolled, mobileMenuOpen }) => scrolled ? `
    // color: ${ colors.bgColor };
      // display: none;
      visibility: hidden;
      font-size: 0.8rem;  

      ${ mq.mediumAndBelow } {
        ${ mobileMenuOpen ? `
          visibility: hidden;
        ` : `` }
      }
    ` : `
    ${ mq.mediumAndBelow } {
      ${ mobileMenuOpen ? `
      visibility: hidden;
      ` : `` }
    }
    ` }
  }

`

const ConditionalWrapper = ({ condition, wrapper, children }) => 
  condition ? wrapper(children) : children;

const Header = ({
  location,
  hasAtf = false,
  bannerText,
  collapsed,
  bannerColor,
  navigation,
  title,
  pageTitle
}) => {
  const [ mobileMenuOpen, toggleMobileMenu ] = useState(false)
  const [ bannerVisible, toggleBanner ] = useState(true)

  let pathname = location
  let pageHasAtf = hasAtf && !mobileMenuOpen

  return (
    <Fragment>
      <ScrollListener.Consumer>
        {({
          scrolledToTop,
          scrolledToBottom,
          scrollY,
          scrolledUp,
          hasScrolled,
          pageHeight
        }) => {
          let scrolled = false
          if (collapsed) {
            scrolled = true
          } else {
            scrolled = !scrolledToTop && hasScrolled
          }
          return (
            <Wrapper hasAtf={pageHasAtf} navVisible={!scrolledUp && !scrolledToTop && showHide}>
              {bannerText && (<div>
                <HeaderNotificationBanner
                  closeBanner={() => toggleBanner(false)}
                  collapsed={!bannerVisible}
                  text={bannerText}
                  setTheme={bannerColor}
                />
              </div>)}
              <HeaderWrapper
                navVisible={!scrolledUp && !scrolledToTop && showHide}
                hasAtf={pageHasAtf}
                scrolled={scrolled}
                mobileMenuOpen={mobileMenuOpen}
              >
                <HeaderContent
                  small="2 [13] [1] 12"
                  medium="1 [10] [12] 5"
                  large="1 [10] [12] 5"
                  vAlign="middle"
                  hasAtf={pageHasAtf}
                  navVisible={!scrolledUp && !scrolledToTop && showHide}
                >
                  <PageTitle 
                    scrolled={scrolled}
                    mobileMenuOpen={mobileMenuOpen}
                  >
                    <ConditionalWrapper
                      condition={pageTitle === 'What is Asia?'}
                      wrapper={children =>  <Link to={'/what-is-asia'}>{children}</Link>}
                    >
                      <h1>{pageTitle}</h1>
                    </ConditionalWrapper>  
                  </PageTitle>
                  
                  <HeaderCenter>
                    <ResponsiveComponent
                      small={<span></span>
                      }
                      medium={
                        <NavLinks
                          scrolled={scrolled}
                          mobileMenuOpen={mobileMenuOpen}
                        >

                        {navigation && navigation.map((link, index) => {
                          // console.log("NAVLINK:::", link)
                          let destination = link.to.to || '/' + link.to.linkToPage.slug
                          // console.log('DESTINATION:::', destination)
                          return (
                          <li key={'header-link-' + link.id}>
                            <NavLink
                              scrolled={scrolled}
                              hasAtf={pageHasAtf}
                              to={destination}
                              active={pathname === destination}
                              key={index}
                              hasDropdown={link.dropdownLinks}
                              external={ link.to.to ? true : false }
                              target={ link.to.to ? '_blank' : '_self'}
                            >
                              {link.label}
                            </NavLink>
                            {link.dropdownLinks && (
                              <Dropdown>
                                {link.dropdownLinks.map((dropdownLink, index) => (
                                  <li key={dropdownLink.id}>
                                    <Link to={'/' + dropdownLink.to.slug}>{dropdownLink.label}</Link>
                                  </li>
                                ))}
                              </Dropdown>
                            )}
                          </li>
                        )}
                        )}
                        </NavLinks>
                      }
                    />
                  </HeaderCenter>
                  
                </HeaderContent>
              </HeaderWrapper>
                  <LogoCol>
                    <ResponsiveComponent
                      small={
                        <LogoWrapper
                          onClick={() => toggleMobileMenu(!mobileMenuOpen)} aria-label='Toggle Navigation'
                        >
                          <HeaderLogo
                            scrolled={scrolled}
                            hasAtf={pageHasAtf}
                            mobileMenuOpen={mobileMenuOpen}
                          />
                        </LogoWrapper>
                      }
                      medium={
                        <LogoWrapper
                          aria-label='Home Page'
                        >
                        <Link to="/" title={title}>
                          <HeaderLogo
                            scrolled={scrolled}
                            hasAtf={pageHasAtf}
                            mobileMenuOpen={mobileMenuOpen}
                          />
                        </Link>
                        </LogoWrapper>
                      }
                    />
                  </LogoCol>
            </Wrapper>
          )
        }}
      </ScrollListener.Consumer>

      {!hasAtf && (
        <HeaderPlaceholder hasBanner={bannerText && bannerVisible} />
      )}

      <ResponsiveComponent
        small={
          <MobileMenu
            open={mobileMenuOpen}
            toggleMobileMenu={toggleMobileMenu}
            navLinks={navigation}
            pathname={pathname}
          // footerColumn1={footerColumn1}
          // footerColumn2={footerColumn2}
          />
        }
        medium={<span />}
      />

    </Fragment>
  )
}

export default Header
